





































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace } from 'vuex-class';
import GUUID from '@/utils/GUUID';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import { CommunityUserVisitedFilter } from '@/graphql/_Filters/CommunityUserVisitedFilter';
import CommunityUserVisited from '@/models/graphql/CommunityUserVisited';

const communityUserVisitedStore = namespace('CommunityUserVisitedStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonVisit extends VueBaseActionButtonWidget {
  @communityUserVisitedStore.Action
  markAsVisited!: (payload: CommunityUserVisitedFilter) => Promise<CommunityUserVisited | undefined>;

  @communityUserVisitedStore.Action
  markUnvisited!: (payload: CommunityUserVisitedFilter) => Promise<CommunityUserVisited | undefined>;

  @Prop({ required: true })
  protected readonly actionResult!: string | null;

  @communityUserVisitedStore.Getter
  private isLoading!: boolean;

  private isVisited: string | null = this.actionResult;

  private get text(): string {
    return `${this.isVisited ? this.$t('actions.visited') : this.$t('actions.visit')}`;
  }

  private get variant(): string {
    return this.isVisited ? 'alt-2' : 'secondary';
  }

  private get icon(): string {
    return this.isVisited ? 'fa-solid fa-flag' : 'fa-regular fa-flag';
  }

  private get isDisplayed(): boolean {
    return (this.actionType === this.ActionButtonType.VISIT);
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('actionResult')
  private syncActionResultData(): void {
    this.isVisited = this.actionResult;
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private toggleVisited(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isVisited) {
      const backupValue = this.isVisited;
      this.isVisited = null;
      this.markUnvisited({ uid: backupValue })
        .catch(() => {
          this.isVisited = backupValue;
        });
    } else {
      this.isVisited = GUUID.uuidv4();
      this.markAsVisited({
        userId: this.authUser.uid,
        linkedEntityId: this.entityUid,
        entityType: this.entityType,
      })
        .then((response) => {
          if (response) {
            this.logStats(StatLoggerCategories.VISIT, StatLoggerActions.ADD, '');
            this.isVisited = response.uid;
          } else {
            this.isVisited = null;
          }
        })
        .catch(() => {
          this.isVisited = null;
        });
    }
  }
}
